
$(document).ready(function () {

  if($('.wrapper .box').length){

    var ww = $(window).width();
    var wh = $(window).height();

    var redBox = document.getElementsByClassName('red box');
    var blueBox = document.getElementsByClassName('blue box');
    var whiteBox = document.getElementsByClassName('white box');

    var redBoxTop = wh*.30;
    var redBoxLeft = ww*.45;
    redBox[0].style.top = redBoxTop;
    redBox[0].style.left = redBoxLeft;

    var whiteBoxTop = wh*.10;
    var whiteBoxLeft = ww*.41;
    whiteBox[0].style.top = whiteBoxTop;
    whiteBox[0].style.left = whiteBoxLeft;

    var blueBoxTop = wh*.20;
    var blueBoxLeft = ww*.23;
    blueBox[0].style.top = blueBoxTop;
    blueBox[0].style.left = blueBoxLeft;

    var hero = document.getElementById('hero');
    hero.addEventListener("mousemove", function(e){

      var x = e.pageX,
      y = e.pageY;

      redBox[0].style.top = (redBoxTop - (y / 30)) + 'px';
      redBox[0].style.left = (redBoxLeft - (x / 30)) + 'px';

      whiteBox[0].style.top = (whiteBoxTop - (y / 10)) + 'px';
      whiteBox[0].style.left = (whiteBoxLeft - (x / 20)) + 'px';


      blueBox[0].style.top = (blueBoxTop - (y / 50)) + 'px';
      blueBox[0].style.left = (blueBoxLeft - (x / 50)) + 'px';
    });
  };

  var d = new Date()
  var current_year = d.getFullYear();
  $('.copywrite.year').html(current_year);

  $('.background').parent().css({
    'position': 'relative',
    'overflow': 'hidden'
  });

  $('#listing-swiper').each(function(){
    new Swiper (this, {
        // Optional parameters
        direction: 'horizontal',
        loop: false,
        slidesPerView : 4,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        breakpoints: {
          1324: {
            slidesPerView: 2,

          },
          968: {
            slidesPerView: 2,

          },
          640: {
            slidesPerView: 1,

          },
          375: {
            slidesPerView: 1,

          },
        },
     });
  });
  $('#office-swiper').each(function(){
    new Swiper (this, {
        // Optional parameters
        direction: 'horizontal',
        loop: false,
        slidesPerView : 4,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        breakpoints: {
          1324: {
            slidesPerView: 2,

          },
          968: {
            slidesPerView: 2,

          },
          640: {
            slidesPerView: 1,

          },
          375: {
            slidesPerView: 1,

          },
        },
     });
  });

  if($('#brag-slider').length > 0){
    var brag_swiper = new Swiper ('#brag-swiper', {
      // Optional parameters
      direction: 'vertical',
      slidesPerView : 1,
      speed:1000,
      autoplay: {
        delay: 3000,
        disableOnInteraction: false,
      },
    });

    var border_swiper = new Swiper ('#brag-swiper-2', {
      // Optional parameters
      effect: 'flip',
      centeredSlides: true,
      slidesPerView: 2,
      direction: 'vertical',
      flipEffect: {
        rotate: 30,
        slideShadows: false,
      },

    });
    var border_swiper_three = new Swiper ('#brag-swiper-3', {
      // Optional parameters
      effect: 'flip',
      direction: 'horizontal',
      centeredSlides: true,
      slidesPerView: 3,
      flipEffect: {
        rotate: 30,
        slideShadows: false,
      },
    });
    brag_swiper.controller.control = border_swiper;
    border_swiper.controller.control = brag_swiper;

    border_swiper.controller.control = border_swiper_three;
    border_swiper_three.controller.control = border_swiper;

  };

  $.animate_sign_insert = function(){
    anime({
      targets: '.insert',
      translateY:[
        {value: '-=113', duration: 750, easing: 'easeOutCubic'},
        {value: '+=113', duration: 750, delay: 750}
      ],
      rotateX: [
        {value: 360, duration: 1000, delay: 1050}
      ],
      update: function(x) {
        if (x.currentTime >= 1200) {
          document.querySelector("#insert_text").innerHTML ="    SOLD";
          document.querySelector("#insert_text").style.fill = "rgba(136, 0, 0, 1)";
          document.querySelector("#insert_text").style.stroke = "rgba(136, 0, 0, 1)";
        }
      },
    });
  };

  $('#sign_grid').one('mouseenter', function(){
    $.animate_sign_insert();
  });

 $('.accordion').accordion({
   selector :{
     trigger: '.toggling'
   }
 });

	if ($(window).width() <= 768){
    $('#information > .grid, #sub-header > .grid').addClass('center aligned');
	}

});
